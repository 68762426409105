@import '../../style/themes/index';
@import '../../style/mixins/index';

@message-prefix-cls: ~'@{ant-prefix}-message';

.@{message-prefix-cls} {
  .reset-component;

  position: fixed;
  top: 16px;
  left: 0;
  z-index: @zindex-message;
  width: 100%;
  pointer-events: none;

  &-notice {
    padding: 8px;
    text-align: center;
    &:first-child {
      margin-top: -8px;
    }
  }

  &-notice-content {
    display: inline-block;
    padding: @message-notice-content-padding;
    background: @component-background;
    border-radius: @border-radius-base;
    box-shadow: @shadow-2;
    pointer-events: all;
  }

  &-success .@{iconfont-css-prefix} {
    color: @success-color;
  }

  &-error .@{iconfont-css-prefix} {
    color: @error-color;
  }

  &-warning .@{iconfont-css-prefix} {
    color: @warning-color;
  }

  &-info .@{iconfont-css-prefix},
  &-loading .@{iconfont-css-prefix} {
    color: @info-color;
  }

  .@{iconfont-css-prefix} {
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: @font-size-lg;
  }

  &-notice.move-up-leave.move-up-leave-active {
    overflow: hidden;
    animation-name: MessageMoveOut;
    animation-duration: 0.3s;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

@text-color: #1c1c1c;@primary-color: #3B8CFF;@primary-5: #4F98FF;@primary-7: #357EE6;@link-color: #333333;@btn-disable-color: rgba(#FFFFFF, 0.8);@btn-disable-bg: rgba(#3B8CFF, 0.5);@btn-disable-border: transparent;@font-feature-settings-base: normal;@font-variant-base: normal;@menu-icon-size: 1.5rem;@menu-highlight-color: #FFFFFF;@menu-item-active-bg: #3B8CFF;@menu-item-active-border-width: 0;@menu-item-vertical-margin: 0;@layout-body-background: #ECECEB;@layout-footer-background: #ECECEB;@layout-header-background: #FFFFFF;@layout-header-height: 2.875rem;@layout-header-padding: 0 0.5rem;@tooltip-bg: #3B8CFF;@item-hover-bg: rgba(#B1D1FF, 0.3);@item-active-bg: rgba(#B1D1FF, 0.3);@table-row-hover-bg: rgba(#B1D1FF, 0.3);@select-item-selected-bg: rgba(#B1D1FF, 0.3);@select-item-active-bg: rgba(#B1D1FF, 0.3);@link-decoration: underline;@link-hover-decoration: underline;@link-hover-color: #000000;@link-active-color: #000000;@input-border-color: #E0E0E0;@slider-margin: 0.625rem 0;@slider-rail-background-color: #E0E0E0;@slider-rail-background-color-hover: #E0E0E0;@slider-track-background-color: #3B8CFF;@slider-track-background-color-hover: #4F98FF;@slider-handle-background-color: #FFFFFF;@slider-handle-color: #3B8CFF;@slider-handle-color-hover: #4F98FF;@modal-heading-color: #3B8CFF;@modal-footer-border-color-split: rgba(0, 0, 0, 0);@modal-mask-bg: rgba(#000000, 0.8);@form-error-input-bg: #FEEDF2;@error-color: #ED1C24;